import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import styled, { ThemeProvider } from 'styled-components';
import { themeState } from 'styleguide/theme';
import { LoginViewModel } from './LoginViewModel';
import { ScreenContainer } from 'styleguide/ScreenContainer';
import { Footer } from 'components/Footer';
import DownloadGooglePlay from 'assets/download-googleplay.svg';
import { LoginCard } from './components/LoginCard';
import { ForgotPasswordCard } from './components/ForgotPasswordCard';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { colors } from 'styleguide/colors';

export const LoginScreen = observer(() => {
  const [vm] = useState(() => new LoginViewModel());

  const router = useRouter();

  const redirectLoggedInUser = (isNewUser: boolean) => {
    if (isNewUser) {
      router.push('/onboarding/username');
      return;
    }
    const initialRoute = router.query.postLoginPath as string | undefined;
    router.push(initialRoute || '/');
  };

  const onLogin = async () => {
    if (!vm.isLoginButtonEnabled) return;

    themeState.push('light');
    try {
      await vm.onLoginPress();
      const initialRoute = router.query.postLoginPath as string | undefined;
      router.push(initialRoute || '/').then(() => themeState.pop());
    } catch {
      themeState.pop();
    }
  };

  const onGooglePress = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const result = await vm.onLoginWithGoogleSuccess(response);
    if (!result) return;
    redirectLoggedInUser(result.is_new_user);
  };

  const onApplePress = async () => {
    const result = await vm.onLoginWithAppleClick();
    if (!result) return;
    redirectLoggedInUser(result.is_new_user);
  };

  return (
    <ThemeProvider theme={themeState.current}>
      <ScreenContainer>
        <Container>
          <Content>
            {/*
             * preload the SVG gradient from the Google Play logo. if there are multiple
             * SVGs with the same linearGradient `id` on the same page, hiding the first one
             * using `display: none` makes the gradient disappear from all of them.
             * something like https://github.com/elderapo/react-svg-unique-id/ would be a
             * better fix, but the library is outdated and doesn't work within our environment.
             */}
            <DownloadGooglePlay style={{ width: 0, height: 0, position: 'absolute' }} />
            <LeftContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
              <LeftContent>
                {vm.screenState === 'login' && (
                  <LoginCard
                    usernameOrEmail={vm.usernameOrEmail}
                    onUpdateUsernameOrEmail={vm.onUpdateUsernameOrEmail}
                    password={vm.password}
                    onUpdatePassword={vm.onUpdatePassword}
                    isLoginEnabled={vm.isLoginButtonEnabled}
                    isLoading={vm.isLoading}
                    onLogin={onLogin}
                    loginErrorMessage={vm.errorMessage}
                    onGoogleLogin={onGooglePress}
                    onAppleLogin={onApplePress}
                    onForgotPassword={() => vm.setScreenState('forgot-password')}
                    onNewUserPress={() => {
                      router.push('/signup');
                    }}
                  />
                )}
                {vm.screenState === 'forgot-password' && (
                  <ForgotPasswordCard
                    isEnabled={vm.isSendPasswordRecoveryEnabled}
                    isLoading={vm.isPasswordRecoveryEmailLoading}
                    onUpdateEmail={vm.onUpdatePasswordRecoveryEmail}
                    onSendPasswordRecovery={vm.onSendPasswordRecovery}
                    onBackToLogin={() => vm.setScreenState('login')}
                  />
                )}
              </LeftContent>
            </LeftContainer>
            <RightContainer>
              <RightContent>
                <svg
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  width="403"
                  height="253"
                  viewBox="0 0 403 253"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-415.494 -1071.33C-387.948 -1107.36 -339.606 -1120.34 -297.718 -1102.96L339.1 -838.651C377.701 -822.63 402.267 -784.294 400.696 -742.53L373.544 -20.8566C371.898 22.9048 341.969 60.504 299.692 71.9238L-349.776 247.357C-392.316 258.848 -437.364 241.14 -460.691 203.756L-841.842 -407.063C-863.882 -442.384 -861.733 -487.664 -836.446 -520.739L-415.494 -1071.33Z"
                    stroke="#1D83EA"
                    strokeWidth="3"
                  />
                </svg>
                <svg
                  style={{ position: 'absolute', bottom: 0, left: '45%' }}
                  width="1041"
                  height="886"
                  viewBox="0 0 1041 886"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M584.357 41.4378C611.903 5.40919 660.245 -7.57131 702.133 9.81377L1552.5 362.753C1591.11 378.774 1615.67 417.11 1614.1 458.874L1577.95 1419.73C1576.3 1463.49 1546.38 1501.09 1504.1 1512.51L635.515 1747.13C592.975 1758.62 547.927 1740.91 524.6 1703.53L17.0267 890.108C-5.01349 854.787 -2.86418 809.506 22.4225 776.432L584.357 41.4378Z"
                    stroke="#1D83EA"
                    strokeWidth="3"
                  />
                </svg>
                <ScreenshotImage />
              </RightContent>
            </RightContainer>
          </Content>
        </Container>
      </ScreenContainer>
      <Footer showPricingLink={true} />
    </ThemeProvider>
  );
});

const Container = styled.div`
  width: 100vw;
  min-height: calc(100vh - 117px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  @media (max-width: 1024px) {
    padding: 0 40px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
`;

const LeftContent = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 40px 0;
  }
`;

const RightContainer = styled.div`
  display: flex;
  width: 50%;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.neutral100};
  @media (max-width: 1024px) {
    display: none;
  }
`;

const RightContent = styled.div`
  width: 100%;
  position: relative;
  min-height: calc(818px - 2 * 142px);
  height: min(818px, 100vh - 61px - 2 * 142px);
  padding: 142px 0;
`;

const ScreenshotImage = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 1600px;
  z-index: 1;
  background-image: url(/images/login-hero.png);
  background-size: contain;
  background-repeat: no-repeat;
`;
