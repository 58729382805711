import React from 'react';
import { PrimaryButton, TertiaryButton } from 'styleguide/Buttons';
import { Card } from 'styleguide/Card';
import { FormInput } from 'styleguide/Inputs';
import { TextLG, TextMD } from 'styleguide/Texts';
import { View } from 'styleguide/View';

interface Props {
  isEnabled: boolean;
  isLoading: boolean;
  onUpdateEmail: (value: string) => void;
  onSendPasswordRecovery: () => void;
  onBackToLogin: () => void;
}

export const ForgotPasswordCard = ({
  isEnabled,
  isLoading,
  onUpdateEmail,
  onSendPasswordRecovery,
  onBackToLogin,
}: Props) => {
  return (
    <View style={{ maxWidth: 400 }}>
      <Card
        style={{
          width: '100%',
          maxWidth: 400,
        }}
      >
        <View
          style={{
            flex: 1,
            padding: 20,
          }}
        >
          <TextLG style={{ textAlign: 'center', marginBottom: 16 }}>Password Recovery</TextLG>
          <TextMD style={{ marginBottom: 16 }}>
            Enter your email and if an account exists we will send you an email with a link to
            recover your password.
          </TextMD>
          <FormInput
            type={'email'}
            placeholder="Email"
            style={{ marginBottom: '16px' }}
            onChange={e => onUpdateEmail(e.currentTarget.value)}
          ></FormInput>
          <PrimaryButton
            onClick={onSendPasswordRecovery}
            title={'Send Password Recovery'}
            style={{ width: '100%' }}
            enabled={isEnabled}
            loading={isLoading}
          />
        </View>
      </Card>
      <TertiaryButton
        onClick={onBackToLogin}
        style={{ width: '100%', marginTop: 16, textDecoration: 'underline' }}
      >
        Back to Log In
      </TertiaryButton>
    </View>
  );
};
