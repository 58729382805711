import React from 'react';
import Head from 'next/head';
import { GetServerSideProps } from 'next';
import { LoginScreen } from 'screens/Login/LoginScreen';
import { BootstrapLoadingWrapper } from 'components/BootstrapLoadingWrapper';
import { redirectAuthenticatedUser } from 'utils/pageUtils';

export const getServerSideProps: GetServerSideProps = redirectAuthenticatedUser;

export default function Login() {
  const metaTitle = 'Hevy';
  const metaDescription = 'Track workouts, measure your progress and stay motivated';

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:site_name" content="Hevy - Social Workout Tracker" />
        <meta
          property="og:image"
          content="https://www.hevyapp.com/wp-content/uploads/hevy-social-card.jpg"
        />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@hevyapp" />
        <meta name="twitter:site" content="@hevyapp" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta
          name="twitter:image"
          content="https://www.hevyapp.com/wp-content/uploads/hevy-social-card.jpg"
        />
      </Head>
      <BootstrapLoadingWrapper>
        <LoginScreen />
      </BootstrapLoadingWrapper>
    </>
  );
}
