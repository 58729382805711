import React from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import styled from 'styled-components';
import { PrimaryButton, TertiaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { LabelledFormInput } from 'styleguide/Inputs';
import { H1Big, TextMD, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { LoginWithGoogleButton, SocialLoginButton } from '../../../components/SocialLoginButton';

const Spacer = styled.div`
  margin-top: 92px;
  @media (max-width: 1024px) {
    margin-top: 16px;
  }
`;

interface LoginCardProps {
  usernameOrEmail: string;
  isEmailOrUsernameLocked?: boolean;
  onUpdateUsernameOrEmail: (value: string) => void;
  password: string;
  onUpdatePassword: (value: string) => void;
  isLoginEnabled: boolean;
  isLoading: boolean;
  onLogin: () => void;
  loginErrorMessage?: string;
  onGoogleLogin: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  onAppleLogin: () => void;
  onForgotPassword: () => void;
  onNewUserPress: () => void;
}

export const LoginCard = ({
  usernameOrEmail,
  isEmailOrUsernameLocked,
  onUpdateUsernameOrEmail,
  password,
  onUpdatePassword,
  isLoginEnabled,
  isLoading,
  onLogin,
  loginErrorMessage,
  onGoogleLogin,
  onAppleLogin,
  onForgotPassword,
  onNewUserPress,
}: LoginCardProps) => {
  return (
    <View
      style={{
        width: '100%',
        maxWidth: 400,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <Spacer />
        <H1Big style={{ textAlign: 'center', marginBottom: 48 }}>Log In</H1Big>
        <LoginWithGoogleButton
          action="login"
          style={{ marginBottom: 16, justifyContent: 'center' }}
          onSuccess={onGoogleLogin}
          onFailure={() => {
            // TODO Show an error
          }}
        />
        <SocialLoginButton
          action="login"
          style={{ marginBottom: 16 }}
          type="apple"
          onClick={onAppleLogin}
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 16,
            marginBottom: 20,
          }}
        >
          <DividerLine />
          <TextSMMedium type="secondary" style={{ width: '100%', textAlign: 'center' }}>
            Or with email
          </TextSMMedium>
          <DividerLine />
        </View>
        <form onSubmit={e => e.preventDefault()}>
          <LabelledFormInput
            disabled={isEmailOrUsernameLocked ?? false}
            label="Email or username"
            spellCheck={false}
            containerStyle={{ marginBottom: '16px' }}
            value={usernameOrEmail}
            autoCapitalize="none"
            onChange={event => onUpdateUsernameOrEmail(event.currentTarget.value)}
          />
          <LabelledFormInput
            label="Password"
            type={'password'}
            containerStyle={{ marginBottom: '16px' }}
            value={password}
            onChange={event => onUpdatePassword(event.currentTarget.value)}
          />
          <PrimaryButton
            type={'submit'}
            title={'Login'}
            style={{ width: '100%', padding: '24px', marginBottom: '24px' }}
            enabled={isLoginEnabled}
            loading={isLoading}
            onClick={onLogin}
          />
        </form>
        {!!loginErrorMessage && (
          <View>
            <TextMD style={{ color: colors.red, marginBottom: '16px' }}>{loginErrorMessage}</TextMD>
          </View>
        )}
        <TertiaryButton
          onClick={onForgotPassword}
          style={{ width: '100%', marginBottom: 0, textDecoration: 'underline' }}
        >
          Forgot Password
        </TertiaryButton>
        <TertiaryButton
          onClick={onNewUserPress}
          style={{ width: '100%', marginTop: '16px', gap: 4 }}
        >
          <span style={{ color: colors.black }}>New to Hevy?</span>
          <span style={{ textDecoration: 'underline' }}>Sign Up</span>
        </TertiaryButton>
      </View>
    </View>
  );
};
