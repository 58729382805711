import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { isValidEmail, isValidUsername } from 'hevy-shared';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { stores } from 'state/stores';
import { fetchUserContentAfterLogin } from 'utils/fetchUserContent';
import API, { SocialLoginResponse } from 'utils/API';
import { log } from 'utils/log';
import { sendEvent } from 'utils/events';
import { modal } from 'components/Modals/ModalManager';
import axios from 'axios';

const isGoogleLoginResponse = (
  x: GoogleLoginResponse | GoogleLoginResponseOffline,
): x is GoogleLoginResponse => {
  return x.code === undefined;
};

type ScreenState = 'login' | 'forgot-password';

interface LoginViewModelProps {
  afterLogin?: () => Promise<void>;
}

export class LoginViewModel {
  usernameOrEmail: string = '';
  password: string = '';
  errorMessage?: string;
  screenState: ScreenState = 'login';
  isLoading: boolean = false;
  afterLogin?: () => Promise<void>;

  constructor(props?: LoginViewModelProps) {
    this.afterLogin = props?.afterLogin;
    makeAutoObservable(this);

    log('LoginViewModel constructor');
    sendEvent('login_screenview');
  }

  get isValidEmailorUsername() {
    return isValidEmail(this.usernameOrEmail) || isValidUsername(this.usernameOrEmail);
  }

  get isValidPassword() {
    return this.password.length > 5;
  }

  get isLoginButtonEnabled() {
    return this.usernameOrEmail.length > 0 && this.password.length > 0;
  }

  setScreenState = (screenState: ScreenState) => {
    this.screenState = screenState;
  };

  /**
   * Google Login
   */

  onLoginWithGoogleSuccess = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (!isGoogleLoginResponse(response)) return;

    sendEvent('login_click', { source: 'google' });

    const email = response.profileObj.email;
    const userId = response.googleId;
    const idToken = response.tokenId;

    let loginResult: SocialLoginResponse;

    try {
      loginResult = await stores.auth.loginWithGoogle(email, userId, idToken);
    } catch {
      this.errorMessage = 'Failed to login with Google.';
      return;
    }

    sendEvent('login_complete', { source: 'google' });

    await this.afterLogin?.();

    await this.fetchUserData();

    return loginResult;
  };

  /**
   * Apple Login
   */

  onLoginWithAppleClick = async () => {
    sendEvent('login_click', { source: 'apple' });
    this.errorMessage = undefined;

    let loginResult: SocialLoginResponse;

    try {
      const result = await (window as any).AppleID?.auth?.signIn();
      const identityToken = result.authorization?.id_token;
      const maybeEmail = result.user?.email;
      loginResult = await stores.auth.loginWithApple({ identityToken, email: maybeEmail });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.error === 'popup_closed_by_user') return;

      this.errorMessage = 'Failed to login with Apple.';
      return;
    }

    sendEvent('login_complete', { source: 'apple' });

    await this.afterLogin?.();

    await this.fetchUserData();

    return loginResult;
  };

  /**
   * Email Login
   */

  onUpdateUsernameOrEmail = (value: string) => {
    this.usernameOrEmail = value;
  };

  onUpdatePassword = (value: string) => {
    this.password = value;
  };

  onLoginPress = async () => {
    sendEvent('login_click', { source: 'email' });
    this.errorMessage = undefined;

    try {
      this.isLoading = true;
      await stores.auth.login(this.usernameOrEmail, this.password);
      sendEvent('login_complete', { source: 'email' });
      await this.afterLogin?.();
      await this.fetchUserData();
    } catch (e: unknown | import('axios').AxiosError) {
      this.isLoading = false;

      if (axios.isAxiosError(e)) {
        switch (e.response?.data.error) {
          case 'InvalidEmailOrPassword':
            this.errorMessage = 'Invalid username, email or password';
            break;
          default:
            sendEvent('login_error', { source: 'email', error: e.message });
        }

        throw e;
      }

      this.errorMessage = 'An unknown error has occurred';
      throw e;
    }
  };

  passwordRecoveryEmail: string = '';
  isPasswordRecoveryEmailLoading: boolean = false;
  onUpdatePasswordRecoveryEmail = (value: string) => {
    this.passwordRecoveryEmail = value;
  };

  get isSendPasswordRecoveryEnabled() {
    return isValidEmail(this.passwordRecoveryEmail);
  }

  onSendPasswordRecovery = async () => {
    this.isPasswordRecoveryEmailLoading = true;
    try {
      await toast.promise(API.generatePasswordRecoveryEmail(this.passwordRecoveryEmail), {
        loading: 'Sending password reset request',
        success: `Password reset request sent!`,
        error: 'Failed to send email',
      });
      this.setScreenState('login');
      modal.ok({
        title: 'Password Recovery',
        subtitle: `If an account exists with this email, you'll receive an email with instructions for reseting your password shortly. If you're still having trouble accessing your account please contact hello@hevyapp.com`,
        submitButtonTitle: 'Got it!',
      });
    } catch {
    } finally {
      this.isPasswordRecoveryEmailLoading = false;
    }
  };

  private fetchUserData = async () => {
    return fetchUserContentAfterLogin();
  };
}
